import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscribe"
export default class extends Controller {
  connect() {
    // hide notification form fields
    for (const field of this.element.querySelectorAll('.my-5')) {
      field.style.display = 'none'
    }

    // unhide remainder of the form, revealing the submit button
    this.element.style.display = 'inline-block'

    // find submit button
    const submit = this.element.querySelector('input[type=submit]')

    if (!navigator.serviceWorker || !window.PushManager) {
      // notifications not supported by this browser
      this.disable(submit)
    } else if (Notification.permission !== "default") {
      // permission has already been granted or denied
      this.disable(submit)
    } else {
      // prompt for permission when clicked
      submit.addEventListener("click", event => {
        event.stopPropagation()
        event.preventDefault()
        this.disable(submit)

        // extract key and path from this element's attributes
        const key = Uint8Array.from(atob(this.element.dataset.key),
          m => m.codePointAt(0))
        const path = "/websubs"
        

        // request permission, perform subscribe, and post to server
        Notification.requestPermission().then(permission => {
          if (Notification.permission === "granted") {
            navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
              return registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: key
              })
            })
            .then(subscription => {
              subscription = subscription.toJSON()
              let formData = new
                FormData(this.element.querySelector('form'))
              formData.set('subscription[endpoint]',
                subscription.endpoint)
              formData.set('subscription[auth_key]',
                subscription.keys.auth)
              formData.set('subscription[p256dh_key]',
                subscription.keys.p256dh)

              return fetch(path, {
                method: 'POST',
                headers: {'Content-Type':
                  'application/x-www-form-urlencoded'},
                body: new URLSearchParams(formData).toString()
              })
            })
            .catch(error => {
              console.error(`Web Push subscription failed: ${error}`)
            })
          }
        })
      })
    }
  }

  // disable the submit button
  disable(submit) {
    submit.removeAttribute('href')
    submit.style.cursor = 'not-allowed'
    submit.style.opacity = '30%'
  }
}
